<template>
  <div class="support-box">
    <div class="support-left loading-bg">
      <img :lazyLoadSrc="supportIcon" v-lazy-load-img />
    </div>
    <div class="support-right">
      <div class="title">{{ $t("support.supportTitle") }}</div>
      <ul class="fn-clear">
        <li class="fn-left">
          <div class="text1">{{ $t("support.destySupport") }}</div>
          <div class="text2">{{ $t("support.destySupportInfo") }}</div>
          <a
            class="text3"
            href="https://desty.tawk.help/category/desty-store"
            target="_blank"
            @click="clickLearnMore"
          >
            <span class="hover-right">
              {{ $t("support.LearnMore")
              }}<svg-icon
                class-name="iconRight"
                icon-class="arrow-right"
              ></svg-icon
            ></span>
          </a>
        </li>
        <li class="fn-left">
          <div class="text1">{{ $t("support.foundIssue") }}</div>
          <div class="text2">{{ $t("support.foundIssueInfo") }}</div>
          <a
            class="text3"
            href="https://www.desty.app/ajak"
            target="_blank"
            @click="clickContactus"
          >
            <span class="hover-right">
              {{ $t("support.tellUs") }}
              <svg-icon
                class-name="iconRight"
                icon-class="arrow-right"
              ></svg-icon
            ></span>
          </a>
        </li>
        <li class="fn-left">
          <div class="text1">{{ $t("support.supportOurTeam") }}</div>
          <div class="text2">{{ $t("support.supportOurTeamInfo") }}</div>
          <a
            class="text3"
            href="https://wa.me/628995533789?text=Hallo%2C+saya+butuh+bantuan+%3A%29"
            target="_blank"
            @click="clickSupportUs"
          >
            <span class="hover-right">
              {{ $t("support.reportUs") }}
              <svg-icon
                class-name="iconRight"
                icon-class="arrow-right"
              ></svg-icon
            ></span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      enterNumber: "",
      supportIcon: `${process.env.VUE_APP_STORE_OSS_URL}/home/support1.webp`,
    };
  },
  methods: {
    clickLearnMore() {
      this.$logEvent("ds-s:click desty support");
    },
    clickContactus() {
      this.$logEvent("ds-s:click contact us");
    },
    clickSupportUs() {
      this.$logEvent("ds-s:click support us");
    },
  },
};
</script>

<style lang="less" scoped>
.support-box {
  background: #c8d0fb;
  display: flex;
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
  .support-left {
    max-width: 598px;
    max-height: 100%;
    width: 100%;
    img {
      width: auto;
      height: 100%;
      object-fit: cover;
    }
    @media screen and (max-width: 960px) {
      max-width: 100%;
      height: auto;
      img {
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }
  }
  .support-right {
    padding: 75px 80px;
    box-sizing: border-box;
    @media screen and (max-width: 960px) {
      padding: 46px 15px;
    }
    .title {
      font-style: normal;
      font-family: @font-family-bold;
      font-size: 48px;
      line-height: 60px;
      color: #000000;
      margin-bottom: 50px;
      max-width: 538px;
      @media screen and (max-width: 960px) {
        font-size: 30px;
        line-height: 38px;
        margin-bottom: 46px;
        padding-right: 40px;
        max-width: 100%;
        box-sizing: border-box;
      }
    }
    li {
      padding-right: 60px;
      width: 50%;
      box-sizing: border-box;
      font-style: normal;
      font-size: 16px;
      line-height: 26px;
      color: #000000;
      margin-bottom: 50px;
      @media screen and (max-width: 960px) {
        width: 100%;
        margin-bottom: 40px;
        padding-right: 0;
      }
      .text1 {
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 24px;
        @media screen and (max-width: 960px) {
          margin-bottom: 18px;
        }
      }
      .text2 {
        margin-bottom: 28px;
        @media screen and (max-width: 960px) {
          margin-bottom: 18px;
        }
      }
      .text3 {
        font-size: 14px;
        line-height: 20px;
        text-transform: uppercase;
        color: #2e53af;
        font-family: @font-family-bold;
        text-decoration: none;
      }
    }
  }
  .iconRight {
    width: 14px;
    height: 10px;
    color: #2e53af;
    margin-left: 14px;
  }
}
</style>
